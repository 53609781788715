<template>
  <div class="d-flex flex-column home-view">
    <FeaturedNewsSection
      :items="parsedFeaturedCoverNewsItems"
      :items-slide="featuredSlideNewsSection.items"
    />

    <BannerWidget v-if="smAndDown" category="Top 2 (728x90)" />

    <NewsSection v-bind="parsedLatestNewsSection" />

    <v-container class="py-0">
      <BannerWidget
        class="mb-2"
        category="Right 3 (379x90)"
        :type="xsOnly ? 'col' : 'row'"
        :items-to-show="{
          xs: 4,
          sm: 4,
          md: 3,
          lg: 3,
          xl: 3,
        }"
        :cols="{
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 3,
        }"
      />
    </v-container>

    <v-container class="home-view_news-sections py-0">
      <div>
        <NewsSection
          v-for="({ title, titleLink, items, cols }, i) in newsSections"
          :key="i"
          class="mb-3 mb-sm-4"
          :title="title"
          :title-link="titleLink"
          :items="items"
          :cols="cols"
          no-container
          hide-category
        />
        <NewsSection
          v-bind="varietiesSection"
          base-path="/variedades"
          no-container
          hide-category
        />
      </div>
      <div>
        <BannerWidget
          class="mb-2"
          category="Quadrado"
          type="col"
          :items-to-show="{
            xs: 6,
            sm: 3,
            md: 6,
            lg: 5,
            xl: 5,
          }"
          :cols="{
            xs: 2,
            sm: 3,
            md: 1,
            lg: 1,
            xl: 1,
          }"
        />
      </div>
    </v-container>

    <NewsSection v-bind="mostReadNewsSection" hide-category hide-call dark />

    <GalleriesSection :items="galleries" />

    <v-container class="py-0 px-0 px-md-4">
      <BannerWidget
        category="Bottom (728x90)"
        type="row"
        :items-to-show="{
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 2,
        }"
      />
    </v-container>

    <VideosSection
      :items="adsReport"
      :cols="videosCols"
      title="Informe Publicitário"
      title-link="/informe-publicitario"
      base-path="/informe-publicitario"
      dark
    />

    <NewsSection v-bind="articlesSection" base-path="/artigos" no-padding />

    <VideosSection
      :items="videos"
      :cols="videosCols"
      title="Vídeos"
      title-link="/videos"
      base-path="/videos"
      dark
    />
  </div>
</template>

<script>
import BannerWidget from "../components/widgets/BannerWidget.vue";
import VideosSection from "../components/sections/VideosSection.vue";
import FeaturedNewsSection from "../components/sections/FeaturedNewsSection.vue";
import GalleriesSection from "../components/sections/GalleriesSection.vue";
import NewsSection from "../components/sections/NewsSection.vue";

const cols = { xs: 2, sm: 2, md: 2, lg: 4, xl: 4 };
const fetch = { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 };

export default {
  data: () => ({
    videosCols: {
      xs: 1,
      sm: 2,
      md: 2,
      lg: 3,
      xl: 3,
    },
    videosFetch: {
      xs: 3,
      sm: 4,
      md: 4,
      lg: 6,
      xl: 6,
    },
    featuredCoverNewsSection: {
      items: [],
      fetch: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
    },
    featuredSlideNewsSection: {
      items: [],
      fetch: { xs: 5, sm: 5, md: 5, lg: 5, xl: 5 },
    },
    latestNewsSection: {
      title: "Últimas Notícias",
      titleLink: "/noticias",
      items: [],
      cols: { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 },
      fetch: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4 },
      noPadding: true,
    },
    mostReadNewsSection: {
      title: "Mais Acessadas",
      titleLink: "/mais-acessadas",
      items: [],
      itemType: "horizontal",
      cols: { xs: 2, sm: 3, md: 4, lg: 4, xl: 4 },
      fetch: { xs: 10, sm: 15, md: 20, lg: 20, xl: 20 },
    },
    newsSections: [
      {
        title: "Colíder",
        titleLink: "/colider",
        cityId: 32,
        items: [],
        cols,
      },
      {
        title: "Polícia",
        titleLink: "/policia",
        categoryId: 6,
        items: [],
        cols,
      },
      {
        title: "Política",
        titleLink: "/política",
        categoryId: 7,
        items: [],
        cols,
      },
      {
        title: "Agronegócios",
        titleLink: "/agronegocios",
        categoryId: 1,
        items: [],
        cols,
      },
    ],
    varietiesSection: {
      title: "Variedades",
      titleLink: "/variedades",
      items: [],
      fetch,
      cols,
    },
    videos: [],
    adsReport: [],
    articlesSection: {
      title: "Artigos",
      titleLink: "/artigos",
      itemType: "cover",
      itemAspectRatio: 1 / 1.618,
      items: [],
      cols,
      fetch,
    },
    galleries: [],
  }),
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    featuredNewsIds() {
      return [
        ...this.featuredCoverNewsSection.items.map(({ id }) => id),
        ...this.parsedFeaturedCoverNewsItems.map(({ id }) => id),
      ];
    },
    latestNewsIds() {
      return this.parsedLatestNewsSection.items.map(({ id }) => id);
    },
    parsedFeaturedCoverNewsItems() {
      const featuredSlideNewsIds = this.featuredSlideNewsSection.items.map(
        ({ id }) => id
      );

      return this.featuredCoverNewsSection.items
        .filter(({ id }) => !featuredSlideNewsIds.includes(id))
        .slice(0, this.featuredCoverNewsSection.fetch[this.breakpointName]);
    },
    parsedLatestNewsSection() {
      const section = { ...this.latestNewsSection };

      section.items = this.latestNewsSection.items
        .filter(({ id }) => !this.inFeaturedNewsId(id))
        .slice(0, this.latestNewsSection.fetch[this.breakpointName]);

      return section;
    },
  },
  methods: {
    inFeaturedNewsId(id) {
      return this.featuredNewsIds.includes(id);
    },
    inLatestNewsId(id) {
      return this.latestNewsIds.includes(id);
    },
    getFeaturedSlideNews() {
      this.$rest("news").get({
        params: {
          featuredSlide: 1,
          itemsPerPage:
            this.featuredSlideNewsSection.fetch[this.breakpointName],
        },
        save: (state, data) => (this.featuredSlideNewsSection.items = data),
      });
    },
    getFeaturedCoverNews() {
      this.$rest("news").get({
        params: {
          featuredCover: 1,
          itemsPerPage:
            this.featuredCoverNewsSection.fetch[this.breakpointName] +
            this.featuredSlideNewsSection.fetch[this.breakpointName],
        },
        save: (state, data) => (this.featuredCoverNewsSection.items = data),
      });
    },
    getLatestNews() {
      this.$rest("news").get({
        params: {
          featuredCover: 1,
          itemsPerPage:
            this.latestNewsSection.fetch[this.breakpointName] +
            this.featuredCoverNewsSection.fetch[this.breakpointName] +
            this.featuredSlideNewsSection.fetch[this.breakpointName],
        },
        save: (state, data) => (this.latestNewsSection.items = data),
      });
    },
    getMostReadNews() {
      this.$rest("news").get({
        params: {
          itemsPerPage: this.mostReadNewsSection.fetch[this.breakpointName],
          orderBy: "views",
        },
        save: (state, data) => (this.mostReadNewsSection.items = data),
      });
    },
    getNews() {
      this.newsSections.forEach((section) => {
        const { categoryId, cityId } = section;

        const params = {
          featuredCategory: 1,
          itemsPerPage:
            fetch[this.breakpointName] +
            this.latestNewsSection.fetch[this.breakpointName] +
            this.featuredCoverNewsSection.fetch[this.breakpointName] +
            this.featuredSlideNewsSection.fetch[this.breakpointName],
        };

        if (categoryId) params.category = categoryId;

        if (cityId) params.city = cityId;

        this.$rest("news").get({
          params,
          save: (state, data) => {
            section.items = data
              .filter(
                ({ id }) =>
                  !this.inFeaturedNewsId(id) && !this.inLatestNewsId(id)
              )
              .slice(0, fetch[this.breakpointName]);
          },
        });
      });
    },
    getArticles() {
      this.$rest("articles").get({
        params: {
          itemsPerPage: this.articlesSection.fetch[this.breakpointName],
        },
        save: (state, data) => (this.articlesSection.items = data),
      });
    },
    getVarieties() {
      this.$rest("varieties").get({
        params: {
          itemsPerPage: this.varietiesSection.fetch[this.breakpointName],
        },
        save: (state, data) => (this.varietiesSection.items = data),
      });
    },
    getVideos() {
      this.$rest("videos").get({
        params: {
          itemsPerPage: this.videosFetch[this.breakpointName],
          featured: 1,
          ads_report: 0,
        },
        save: (state, data) => (this.videos = data),
      });
    },
    getAdsReport() {
      this.$rest("videos").get({
        params: {
          itemsPerPage: this.videosFetch[this.breakpointName],
          featured: 1,
          ads_report: 1,
        },
        save: (state, data) => (this.adsReport = data),
      });
    },
    getGalleries() {
      this.$rest("galleries").get({
        params: {
          itemsPerPage: 5,
          featured: 1,
        },
        save: (state, data) => (this.galleries = data),
      });
    },
  },
  created() {
    this.getFeaturedCoverNews();
    this.getFeaturedSlideNews();
    this.getLatestNews();
    this.getMostReadNews();

    this.getNews();
    this.getVideos();
    this.getAdsReport();
    this.getVarieties();
    this.getArticles();
    this.getGalleries();
  },
  components: {
    BannerWidget,
    NewsSection,
    VideosSection,
    FeaturedNewsSection,
    GalleriesSection,
  },
};
</script>

<style lang="scss">
.home-view {
  gap: 20px 16px;

  &_news-sections {
    display: grid;
    --grid-width: calc((100% - 16px) / 4);
    grid-template-columns: calc(var(--grid-width) * 3) var(--grid-width);
    gap: 20px 16px;
  }
}

@media screen and (max-width: 959.9px) {
  .home-view {
    gap: 12px;
  }

  .home-view_news-sections {
    grid-template-columns: auto;
    gap: 12px;
  }
}
</style>
