var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({staticClass:"share text-none",attrs:{"color":"grey darken-3","depressed":"","text":"","fab":_vm.small,"small":_vm.small}},'v-btn',attrs,false),_vm.customEvents(on)),[_c('v-icon',{attrs:{"left":!_vm.small,"small":""}},[_vm._v(" fa fa-share-alt ")]),(!_vm.small)?_c('span',[_vm._v("Compartilhar")]):_vm._e()],1)]},null,{ on: _vm.customEvents(on) })]}}],null,true)},[_c('v-list',_vm._l((_vm.icons),function(ref,i){
var color = ref.color;
var icon = ref.icon;
var text = ref.text;
var url = ref.url;
return _c('v-list-item',{key:i,staticClass:"text-none text-body-2 font-weight-bold",on:{"click":function($event){return _vm.openWindow(url)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":color,"small":""}},[_vm._v(" "+_vm._s(icon)+" ")]),_vm._v(" "+_vm._s(text)+" ")],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }