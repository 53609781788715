export const mixin = {
  data: () => ({
    fieldProps: {
      outlined: true,
      dense: true,
      color: "primary",
      backgroundColor: "white",
    },
  }),
};
