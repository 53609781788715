<template>
  <SectionTemplate
    v-if="items.length"
    title="Coluna Social"
    title-align="left"
    title-link="/galerias"
    no-padding
  >
    <div class="galleries-section">
      <NewsCard
        v-for="(item, i) in items"
        :key="i"
        :style="generateStyle(i)"
        v-bind="item"
        :type="i === 0 ? 'cover' : 'horizontal'"
        base-path="/galerias"
        hide-date
      />
    </div>
  </SectionTemplate>
</template>

<script>
import NewsCard from "../cards/NewsCard.vue";
import SectionTemplate from "../templates/SectionTemplate.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    generateStyle(i) {
      return {
        "grid-area": `item-${i}`,
      };
    },
  },
  components: {
    SectionTemplate,
    NewsCard,
  },
};
</script>

<style>
.galleries-section {
  display: grid;
  grid-template:
    "item-0 item-1" 1fr
    "item-0 item-2" 1fr
    "item-0 item-3" 1fr
    "item-0  item-4" / 4fr 2fr;
  gap: 12px;
}

@media screen and (max-width: 600px) {
  .galleries-section {
    display: grid;
    grid-template:
      "item-0 item-0" 1fr
      "item-1 item-2" auto
      "item-3 item-4" auto/ 1fr 1fr;
    gap: 12px;
  }
}
</style>
