<template>
  <div
    v-if="itemsSlide.length && items.length"
    class="px-3 mt-3 featured-news-section-container"
  >
    <div class="featured-news-section">
      <CarouselWidget
        class="featured-carousel-large item-0"
        :items="itemsSlide"
        :items-to-show="{
          xs: 1,
          sm: 1,
          md: 1,
          lg: 1,
          xl: 1,
        }"
        :gap="0"
        :nav="!xsOnly"
        :dots="xsOnly"
        nav-inside
      >
        <template #item="{ item }">
          <NewsCard v-bind="item" type="cover" hide-date />
        </template>
      </CarouselWidget>

      <NewsCard
        v-for="(item, i) in items"
        :key="i"
        :class="[`item-${i + 1}`]"
        v-bind="item"
        :type="xsOnly ? 'vertical' : 'cover'"
        hide-date
        detail
      />
    </div>
  </div>
</template>

<script>
import NewsCard from "../cards/NewsCard.vue";
import CarouselWidget from "../widgets/CarouselWidget/Index.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemsSlide: {
      type: Array,
      required: true,
    },
  },
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    NewsCard,
    CarouselWidget,
  },
};
</script>

<style lang="scss">
.featured-news-section-container {
  max-width: 100vw;
}

.featured-news-section {
  display: grid;
  gap: 15px;
  grid-template-columns: 25% 25% 1fr 1fr;

  .item-0 {
    grid-area: 1 / 1 / 3 / 3;
  }

  .item-1 {
    grid-area: 1 / 3 / 2 / 4;
  }

  .item-2 {
    grid-area: 1 / 4 / 2 / 5;
  }

  .item-3 {
    grid-area: 2 / 3 / 3 / 4;
  }

  .item-4 {
    grid-area: 2 / 4 / 3 / 5;
  }

  @media screen and (max-width: 1264px) {
    grid-template-columns: 1fr 1fr;

    .item-0 {
      grid-area: 1 / 1 / 2 / 3;
    }

    .item-1 {
      grid-area: 2 / 1 / 3 / 2;
    }

    .item-2 {
      grid-area: 2 / 2 / 3 / 3;
    }

    .item-3 {
      grid-area: 3 / 1 / 4 / 2;
    }

    .item-4 {
      grid-area: 3 / 2 / 4 / 3;
    }
  }
}
</style>
