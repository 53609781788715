<template>
  <div>
    <NewsCard
      v-bind="{ id, title, img: image }"
      type="cover"
      :base-path="basePath"
      :hide-date="hideDate"
    />
  </div>
</template>

<script>
import NewsCard from "./NewsCard.vue";
import { getYoutubeThumbnail } from "../../utils/getYoutubeThumbnail";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "/videos",
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      return this.img ? this.files + this.img : getYoutubeThumbnail(this.link);
    },
  },
  components: {
    NewsCard,
  },
};
</script>
