<template>
  <PageTemplate title="Contato" no-container simple>
    <SectionTemplate title="Formulário">
      <v-sheet color="transparent" class="mx-auto" max-width="500">
        <ContactForm
          :action="server + formAction"
          response-to="/contato/obrigado"
        />
      </v-sheet>
    </SectionTemplate>
  </PageTemplate>
</template>

<script>
import PageTemplate from "../components/templates/PageTemplate.vue";
import ContactForm from "../components/forms/ContactForm.vue";
import SectionTemplate from "../components/templates/SectionTemplate.vue";

export default {
  data: () => ({
    formAction: "/rest/email/contact-us",
  }),
  components: {
    PageTemplate,
    ContactForm,
    SectionTemplate,
  },
};
</script>
