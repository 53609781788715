<template>
  <v-checkbox
    :rules="rules"
    name="policy_privacy"
    on-icon="fa fa-check-square"
    off-icon="far fa-square"
    class="mt-0 policy-privacy"
    :color="!dark ? 'primary' : 'white'"
  >
    <template #label>
      <div :class="dark && 'white--text'">
        Eu li e concordo com a
        <a @click="showPrivacy" :class="linkClass"> Política de Privacidade </a>
        .
      </div>
    </template>
  </v-checkbox>
</template>

<script>
import { requiredValidation } from "../validations/requiredValidation";

export default {
  props: {
    dark: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rules: [requiredValidation("Você precisa aceitar para continuar.")],
  }),
  computed: {
    linkClass() {
      return ["cursor-pointer font-weight-bold", this.dark && "white--text"];
    },
  },
  methods: {
    showPrivacy: () => window.showPrivacyPolicy(),
  },
};
</script>
