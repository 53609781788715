<template>
  <v-pagination
    color="secondary"
    v-model="page"
    :length="count"
    :total-visible="totalVisible"
  ></v-pagination>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: (val) => val > 1,
    },
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    totalVisible() {
      return this.$vuetify.breakpoint.smAndDown ? 4 : 5;
    },
  },
};
</script>
