var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListTemplate',{attrs:{"title":_vm.title,"mod":_vm.mod,"cols":_vm.cols,"params":_vm.params},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c(_vm.cardComponent,_vm._b({tag:"component",attrs:{"base-path":_vm.basePath,"type":_vm.cardType}},'component',item,false))]}},{key:"side",fn:function(){return [_c('BannerWidget',{attrs:{"category":"Quadrado","type":"col","items-to-show":{
        xs: 6,
        sm: 3,
        md: 6,
        lg: 5,
        xl: 5,
      },"cols":{
        xs: 2,
        sm: 3,
        md: 1,
        lg: 1,
        xl: 1,
      }}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }