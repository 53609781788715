<template>
  <div>
    <v-app-bar class="elevation-0 header-mobile" color="white" fixed tile app>
      <a href="/">
        <img
          v-if="informations.img"
          :src="files + informations.img"
          :alt="informations.name"
          height="46px"
          class="d-block"
        />
      </a>

      <v-spacer />

      <SearchWidget />

      <v-app-bar-nav-icon color="primary" @click="showNavigationDrawer" />
    </v-app-bar>
  </div>
</template>

<script>
import { getSocial } from "../../../utils/getSocial";
import { getWhatsappLinkFromPhone } from "../../../utils/getWhatsappLinkFromPhone";
import { getLinkAttrs } from "../../../utils/getLinkAttrs";
import { formatToURL } from "../../../utils/formatToURL";
import SearchWidget from "../../widgets/SearchWidget.vue";

export default {
  data() {
    return {
      current: this.$route.path,
      drawer: false,
    };
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    newsCategories() {
      return this.$rest("news-categories").list;
    },
    cities() {
      return this.$rest("cities").list;
    },
    menu() {
      return [
        { text: "Início", to: "/inicio" },
        { text: "Sobre Nós", to: "/sobre" },
        {
          text: "Categorias",
          dropdown: this.newsCategories.map(({ title }) => ({
            text: title,
            to: formatToURL(title),
          })),
        },
        {
          text: "Cidades",
          dropdown: this.cities.map(({ title }) => ({
            text: title,
            to: formatToURL(title),
          })),
        },
        { text: "Artigos", to: "/videos" },
        { text: "Eventos", to: "/galerias" },
        { text: "Contato", to: "/contato" },
      ];
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
    getLinkAttrs,
    showNavigationDrawer() {
      window?.showNavigationDrawer();
    },
  },
  components: { SearchWidget },
};
</script>

<style lang="scss" scoped>
.header-mobile {
  border-bottom: 1px solid #ccc !important;
}
</style>
