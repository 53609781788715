<template>
  <SectionTemplate
    v-if="items.length"
    :title="title"
    title-align="left"
    :title-link="titleLink"
    :dark="dark"
  >
    <ListWidget :cols="cols" :gap="gap">
      <VideoCard
        v-for="(item, i) in items"
        :key="i"
        v-bind="item"
        :base-path="basePath"
        hide-date
      />
    </ListWidget>
  </SectionTemplate>
</template>

<script>
import VideoCard from "../cards/VideoCard.vue";
import SectionTemplate from "../templates/SectionTemplate.vue";
import ListWidget from "../widgets/ListWidget.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Vídeos",
    },
    titleLink: {
      type: String,
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
    },
  },
  methods: {
    generateStyle(i) {
      return {
        "grid-area": `item-${i}`,
      };
    },
  },
  components: {
    SectionTemplate,
    VideoCard,
    ListWidget,
  },
};
</script>
