var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.itemsSlide.length && _vm.items.length)?_c('div',{staticClass:"px-3 mt-3 featured-news-section-container"},[_c('div',{staticClass:"featured-news-section"},[_c('CarouselWidget',{staticClass:"featured-carousel-large item-0",attrs:{"items":_vm.itemsSlide,"items-to-show":{
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        xl: 1,
      },"gap":0,"nav":!_vm.xsOnly,"dots":_vm.xsOnly,"nav-inside":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('NewsCard',_vm._b({attrs:{"type":"cover","hide-date":""}},'NewsCard',item,false))]}}],null,false,2157492076)}),_vm._l((_vm.items),function(item,i){return _c('NewsCard',_vm._b({key:i,class:[("item-" + (i + 1))],attrs:{"type":_vm.xsOnly ? 'vertical' : 'cover',"hide-date":"","detail":""}},'NewsCard',item,false))})],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }