<template>
  <footer class="footer">
    <v-sheet color="primary">
      <v-container class="main py-4 py-md-8">
        <a class="logo" href="/">
          <img
            v-if="informations.img"
            :src="files + informations.img"
            height="80px"
            class="d-block mx-auto mx-md-0 filter-white"
          />
        </a>

        <Informations class="informations" />
        <Menu class="menu" />
        <Categories class="categories" />
        <Cities class="cities" />
        <Social class="social" />
      </v-container>
    </v-sheet>
    <Copy />
  </footer>
</template>

<script>
import Menu from "./Menu.vue";
import Categories from "./Categories.vue";
import Cities from "./Cities.vue";
import Copy from "./Copy.vue";
import Informations from "./Informations.vue";
import Social from "./Social.vue";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  components: { Copy, Social, Informations, Menu, Categories, Cities },
};
</script>

<style lang="scss" scoped>
.main {
  display: grid;
  grid-template:
    "logo menu categories cities" 80px
    "social menu categories cities" 36px
    "informations menu categories cities" auto / minmax(auto, 320px)
    min-content min-content;
  gap: 16px 12px;
  justify-content: space-between;
}

.logo {
  grid-area: logo;
}
.informations {
  grid-area: informations;
}
.categories {
  grid-area: categories;
}
.cities {
  grid-area: cities;
}
.social {
  grid-area: social;
}

.menu {
  grid-area: menu;
}

@media screen and (max-width: 1264px) {
  .main {
    grid-template:
      "logo informations informations menu" max-content
      "social informations informations menu" max-content
      "categories categories cities cities" auto / min-content min-content;
    justify-content: center;
  }
}

@media screen and (max-width: 959.9px) {
  .main {
    grid-template:
      "logo logo" max-content
      "social menu" max-content
      "informations menu" max-content
      "categories categories" auto
      "cities cities" auto / max-content max-content;
  }
}

@media screen and (max-width: 600px) {
  .main {
    grid-template:
      "logo" max-content
      "social" max-content
      "informations" max-content
      "menu" max-content
      "categories" auto
      "cities" auto / auto;
  }
}
</style>
