<template>
  <PageListTemplate :title="title" :mod="mod" :cols="cols" :params="params">
    <template #item="{ item }">
      <component
        :is="cardComponent"
        v-bind="item"
        :base-path="basePath"
        :type="cardType"
      />
    </template>
    <template #side>
      <BannerWidget
        category="Quadrado"
        type="col"
        :items-to-show="{
          xs: 6,
          sm: 3,
          md: 6,
          lg: 5,
          xl: 5,
        }"
        :cols="{
          xs: 2,
          sm: 3,
          md: 1,
          lg: 1,
          xl: 1,
        }"
      />
    </template>
  </PageListTemplate>
</template>

<script>
import PageListTemplate from "../components/templates/PageListTemplate.vue";
import NewsCard from "../components/cards/NewsCard.vue";
import BannerWidget from "../components/widgets/BannerWidget.vue";

const defaultCols = { xs: 2, sm: 1, md: 3, lg: 4, xl: 4 };

/**
 * $route.meta = {
 *   mod: string; // Chave do módulo.
 *   cardComponent: VueComponent; // Componente que será item da lista.
 *   ?title: string | (component) => string; // Título da página.
 *   ?cols: { ?xs: number, ?sm: number, ?md: number, ?lg: number }; // Colunas do grid.
 *   ?params: Record<string, string> | (component) => Record<string, string>; // Parametros da requisição de dados
 * }
 */
export default {
  computed: {
    title() {
      const metaTitle = this.$route.meta.title;

      if (!metaTitle) {
        return this.$route.name;
      }

      if (typeof metaTitle === "function") {
        return metaTitle(this);
      } else {
        return metaTitle;
      }
    },
    mod() {
      return this.$route.meta.mod;
    },
    basePath() {
      return this.$route.meta.basePath || this.$route.path;
    },
    params() {
      let params;

      if (typeof this.$route.meta.params === "function") {
        params = this.$route.meta.params(this);
      } else {
        params = this.$route.meta.params;
      }

      return params;
    },
    cardComponent() {
      return this.$route.meta.cardComponent || "span";
    },
    cardType() {
      return this.$vuetify.breakpoint.smOnly ? "horizontal" : "vertical";
    },
    cols() {
      const mergedCols = Object.assign(defaultCols, this.$route.meta.cols);

      return mergedCols;
    },
  },
  components: {
    PageListTemplate,
    NewsCard,
    BannerWidget,
  },
};
</script>
