<template>
  <div class="grid-widget" :style="cssVars">
    <slot></slot>
  </div>
</template>

<script>
const breakpointNames = ["xl", "lg", "md", "sm", "xs"];

export default {
  props: {
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
      default: () => ({
        xs: 2,
        sm: 3,
        md: 4,
        lg: 5,
        xl: 5,
      }),
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
      default: () => ({
        xs: 12,
        sm: 16,
      }),
    },
  },
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
    parsedCols() {
      for (const breakpointName of breakpointNames) {
        const propValue = +this.cols[breakpointName];

        if (propValue && this.$vuetify.breakpoint[`${breakpointName}AndUp`]) {
          return propValue;
        }
      }

      if (this.cols.xs) {
        return +this.cols.xs;
      }

      return undefined;
    },
    parsedGap() {
      for (const breakpointName of breakpointNames) {
        const propValue = +this.gap[breakpointName];

        if (propValue && this.$vuetify.breakpoint[`${breakpointName}AndUp`]) {
          return propValue + "px";
        }
      }

      if (this.gap.xs) {
        return +this.gap.xs + "px";
      }

      return undefined;
    },
    cssVars() {
      return {
        "--cols": this.parsedCols,
        "--gap": this.parsedGap,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-widget {
  display: grid;
  gap: var(--gap, 16px);
  grid-template-columns: repeat(var(--cols, auto-fit), 1fr);
  justify-content: center;
}
</style>
