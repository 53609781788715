var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SectionTemplate',_vm._b({},'SectionTemplate',{
    title: _vm.title,
    dark: _vm.dark,
    loading: _vm.loading,
    noData: _vm.noData,
    noDataText: _vm.noDataText,
    titleAlign: _vm.titleAlign,
    noContainer: _vm.noContainer,
  },false),[_c('ListWidget',{attrs:{"count-pages":_vm.countPages,"cols":_vm.cols,"gap":_vm.gap}},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }