<template>
  <BaseForm
    class="contact-form"
    :class="{ small: small }"
    :action="action"
    :response-to="responseTo"
    gtm-submit-event="form_submit_contact-us"
    :dark="dark"
  >
    <template #fields>
      <NameField :dark="dark" />
      <TelField :dark="dark" />
      <EmailField :dark="dark" />
      <MessageField :dark="dark" />
      <PolicyPrivacyField :dark="dark" />
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "./BaseForm.vue";
import NameField from "./fields/NameField.vue";
import EmailField from "./fields/EmailField.vue";
import TelField from "./fields/TelField.vue";
import MessageField from "./fields/MessageField.vue";
import PolicyPrivacyField from "./fields/PolicyPrivacyField.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responseTo: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseForm,
    NameField,
    EmailField,
    TelField,
    MessageField,
    PolicyPrivacyField,
  },
};
</script>

<style lang="scss">
.contact-form {
  .base-form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    @media screen and (min-width: 600.001px) {
      & > :nth-child(3),
      & > :nth-child(4),
      & > :nth-child(5) {
        grid-column-end: span 2;
      }
    }
  }

  &.small {
    .base-form-fields {
      grid-template-columns: 1fr;

      & > :nth-child(3),
      & > :nth-child(4),
      & > :nth-child(5) {
        grid-column-end: span 1;
      }
    }
  }
}
</style>
