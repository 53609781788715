<template>
  <div class="d-flex flex-column align-start">
    <div v-for="(item, i) in contacts" :key="i">
      <v-btn
        v-if="!Array.isArray(item)"
        class="white--text text-body-2 text-none btn-contact"
        height="auto"
        min-height="36px"
        max-width="320px"
        :href="item.link"
        :target="item.target"
        :title="item.name"
        depressed
        color="transparent"
      >
        <v-icon v-if="item.icon" :size="item.size" class="mr-2">
          {{ item.icon }}
        </v-icon>

        <span class="font-secondary" v-html="item.text"></span>
      </v-btn>

      <div v-else class="d-flex flex-column align-start">
        <v-btn
          v-for="({ name, text, link, target, icon, size }, i) in item"
          :key="i"
          class="white--text text-body-2 text-none btn-contact"
          height="auto"
          min-height="36px"
          :href="link"
          :target="target"
          :title="name"
          depressed
          color="transparent"
        >
          <v-icon v-if="icon" :size="size" class="mr-2">
            {{ icon }}
          </v-icon>

          <span class="font-secondary" v-html="text"></span>
        </v-btn>
      </div>
    </div>

    <div class="d-flex flex-column align-start">
      <v-btn
        class="white--text text-body-2 text-none btn-contact"
        height="auto"
        min-height="36px"
        to="/politica-de-privacidade"
        color="transparent"
        depressed
      >
        <v-icon size="18" class="mr-2"> fa-shield-alt </v-icon>

        <span class="font-secondary"> Política de Privacidade </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getContact } from "../../../utils/getContact";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-contact::v-deep {
  .v-btn__content {
    max-width: 100%;
    white-space: normal;
  }
}
</style>
