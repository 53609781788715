<template>
  <div class="header-shell">
    <Desktop v-if="!mobile" />
    <Mobile v-else />
  </div>
</template>

<script>
import Desktop from "./Desktop/Index.vue";
import Mobile from "./Mobile.vue";

export default {
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  components: {
    Desktop,
    Mobile,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 959.9px) {
  .header-shell {
    position: sticky;
    top: 0;
    z-index: 6;
  }
}
</style>
