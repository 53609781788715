<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    rounded="xl"
    :min-width="!smAndDown ? '500px' : '100vw'"
    content-class="search-widget"
    :left="!smAndDown"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none font-weight-medium"
        color="grey darken-4"
        rounded
        large
        text
        v-bind="attrs"
        v-on="on"
      >
        <span>Busca</span>
        <v-icon color="primary" right>fas fa-search</v-icon>
      </v-btn>
    </template>
    <v-form class="white" @submit.prevent="search" role="form">
      <v-text-field
        ref="field"
        class="search-widget_input"
        append-icon="fa-search"
        @click:append="search"
        placeholder="Buscar"
        :height="!smAndDown ? '48px' : '54px'"
        v-model="q"
        hide-details
        outlined
        rounded
        dense
        block
        focus
      ></v-text-field>
    </v-form>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    q: "",
    openMenu: false,
  }),
  computed: {
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    search() {
      this.openMenu = false;
      this.$router.push({ path: "/busca", query: { q: this.q } });
      this.q = "";
    },
  },
  watch: {
    openMenu(val) {
      if (val) {
        this.$refs.field?.focus();
        // this.$refs.field
      }
    },
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 959.9px) {
  .search-widget {
    left: 0 !important;
    top: 0 !important;
  }
}

.search-widget_input {
  fieldset {
    border-color: #ccc !important;
  }

  .v-input__append-inner {
    margin-top: 12px !important;

    .v-icon {
      color: var(--v-primary-lighten2) !important;
    }
  }
}
</style>
