<template>
  <v-sheet
    v-if="menu.length"
    class="menu"
    :class="{ bordered: !['/'].includes($route.path) }"
    color="white"
  >
    <v-container class="py-0 d-flex align-center justify-center">
      <v-sheet
        class="mx-auto"
        color="transparent"
        max-width="calc(100% - 150px)"
      >
        <v-slide-group show-arrows>
          <v-slide-item v-for="({ text, to }, i) in menu" :key="i">
            <v-btn
              class="
                text-body-1 text-none
                font-weight-bold
                px-3 px-lg-4
                menu-item
              "
              v-bind="getLinkAttrs(to, server)"
              height="52px"
              depressed
              text
              tile
            >
              {{ text }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <SearchWidget />

      <v-app-bar-nav-icon color="primary" @click="showNavigationDrawer" />
    </v-container>
  </v-sheet>
</template>

<script>
import SearchWidget from "../../../widgets/SearchWidget.vue";
import { formatToURL } from "../../../../utils/formatToURL";
import { getLinkAttrs } from "../../../../utils/getLinkAttrs";

export default {
  computed: {
    newsCategories() {
      return this.$rest("news-categories").list.filter(
        ({ featured }) => !!featured
      );
    },
    menu() {
      return this.newsCategories.map(({ title }) => ({
        text: title,
        to: "/" + formatToURL(title),
      }));
    },
  },
  methods: {
    showNavigationDrawer() {
      window?.showNavigationDrawer();
    },
    getLinkAttrs,
  },
  components: {
    SearchWidget,
  },
};
</script>

<style lang="scss" scoped>
.menu {
  border-top: 1px solid #ccc !important;
  border-bottom: 1px solid #ccc !important;

  &-item {
    transition: color 0.1s;

    &:before {
      opacity: 0 !important;
      background-color: white !important;
    }
  }

  &-item.v-btn--active {
    &:before {
      opacity: 1 !important;
      background-color: var(--v-primary-base) !important;
    }

    color: white !important;
  }
}
</style>
