<template>
  <v-sheet color="secondary">
    <v-container
      class="
        d-flex
        flex-column flex-sm-row
        justify-space-between
        align-center
        text-center
      "
    >
      <div class="white--text text-body-2">
        <span class="font-secondary">
          Copyright © 2024 {{ informations.name }}. Todos os direitos
          reservados.
        </span>
      </div>
      <a
        class="d-flex mt-4 mt-sm 0"
        href="https://www.mrxweb.com.br/"
        target="_blank"
      >
        <img
          class="shell-footer-logo"
          src="../../../assets/img/mrxweb.svg"
          alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
          title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
          height="20px"
        />
      </a>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
};
</script>
