<template>
  <SectionTemplate
    :title="title"
    title-align="left"
    :title-link="titleLink"
    :no-container="noContainer"
    :no-padding="noPadding"
    :dark="dark"
    v-if="items.length"
  >
    <ListWidget class="news-section" :cols="cols" :gap="gap">
      <NewsCard
        v-for="(item, i) in items"
        :key="i"
        v-bind="item"
        :type="itemType"
        :aspect-ratio="itemAspectRatio"
        :dark="dark"
        :base-path="basePath"
        :hide-category="hideCategory"
        :hide-call="hideCall"
        hide-date
      />
    </ListWidget>
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../templates/SectionTemplate.vue";
import NewsCard from "../cards/NewsCard.vue";
import ListWidget from "../widgets/ListWidget.vue";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemType: {
      type: String,
    },
    itemAspectRatio: {
      type: Number,
    },
    title: {
      type: String,
    },
    titleLink: {
      type: String,
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
    },
    hideCategory: {
      type: Boolean,
      default: false,
    },
    hideCall: {
      type: Boolean,
      default: false,
    },
  },
  components: { SectionTemplate, NewsCard, ListWidget },
};
</script>
