var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column home-view"},[_c('FeaturedNewsSection',{attrs:{"items":_vm.parsedFeaturedCoverNewsItems,"items-slide":_vm.featuredSlideNewsSection.items}}),(_vm.smAndDown)?_c('BannerWidget',{attrs:{"category":"Top 2 (728x90)"}}):_vm._e(),_c('NewsSection',_vm._b({},'NewsSection',_vm.parsedLatestNewsSection,false)),_c('v-container',{staticClass:"py-0"},[_c('BannerWidget',{staticClass:"mb-2",attrs:{"category":"Right 3 (379x90)","type":_vm.xsOnly ? 'col' : 'row',"items-to-show":{
        xs: 4,
        sm: 4,
        md: 3,
        lg: 3,
        xl: 3,
      },"cols":{
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 3,
      }}})],1),_c('v-container',{staticClass:"home-view_news-sections py-0"},[_c('div',[_vm._l((_vm.newsSections),function(ref,i){
      var title = ref.title;
      var titleLink = ref.titleLink;
      var items = ref.items;
      var cols = ref.cols;
return _c('NewsSection',{key:i,staticClass:"mb-3 mb-sm-4",attrs:{"title":title,"title-link":titleLink,"items":items,"cols":cols,"no-container":"","hide-category":""}})}),_c('NewsSection',_vm._b({attrs:{"base-path":"/variedades","no-container":"","hide-category":""}},'NewsSection',_vm.varietiesSection,false))],2),_c('div',[_c('BannerWidget',{staticClass:"mb-2",attrs:{"category":"Quadrado","type":"col","items-to-show":{
          xs: 6,
          sm: 3,
          md: 6,
          lg: 5,
          xl: 5,
        },"cols":{
          xs: 2,
          sm: 3,
          md: 1,
          lg: 1,
          xl: 1,
        }}})],1)]),_c('NewsSection',_vm._b({attrs:{"hide-category":"","hide-call":"","dark":""}},'NewsSection',_vm.mostReadNewsSection,false)),_c('GalleriesSection',{attrs:{"items":_vm.galleries}}),_c('v-container',{staticClass:"py-0 px-0 px-md-4"},[_c('BannerWidget',{attrs:{"category":"Bottom (728x90)","type":"row","items-to-show":{
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 2,
      }}})],1),_c('VideosSection',{attrs:{"items":_vm.adsReport,"cols":_vm.videosCols,"title":"Informe Publicitário","title-link":"/informe-publicitario","base-path":"/informe-publicitario","dark":""}}),_c('NewsSection',_vm._b({attrs:{"base-path":"/artigos","no-padding":""}},'NewsSection',_vm.articlesSection,false)),_c('VideosSection',{attrs:{"items":_vm.videos,"cols":_vm.videosCols,"title":"Vídeos","title-link":"/videos","base-path":"/videos","dark":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }