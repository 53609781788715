var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-start"},[_vm._l((_vm.contacts),function(item,i){return _c('div',{key:i},[(!Array.isArray(item))?_c('v-btn',{staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","max-width":"320px","href":item.link,"target":item.target,"title":item.name,"depressed":"","color":"transparent"}},[(item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":item.size}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e(),_c('span',{staticClass:"font-secondary",domProps:{"innerHTML":_vm._s(item.text)}})],1):_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((item),function(ref,i){
var name = ref.name;
var text = ref.text;
var link = ref.link;
var target = ref.target;
var icon = ref.icon;
var size = ref.size;
return _c('v-btn',{key:i,staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","href":link,"target":target,"title":name,"depressed":"","color":"transparent"}},[(icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"size":size}},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),_c('span',{staticClass:"font-secondary",domProps:{"innerHTML":_vm._s(text)}})],1)}),1)],1)}),_c('div',{staticClass:"d-flex flex-column align-start"},[_c('v-btn',{staticClass:"white--text text-body-2 text-none btn-contact",attrs:{"height":"auto","min-height":"36px","to":"/politica-de-privacidade","color":"transparent","depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v(" fa-shield-alt ")]),_c('span',{staticClass:"font-secondary"},[_vm._v(" Política de Privacidade ")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }