<template>
  <v-sheet color="secondary" height="42px">
    <v-container class="d-flex py-1 align-center">
      <div class="d-flex white--text text-body-2">
        <span class="font-weight-bold mr-2 d-inline-block">COLÍDER:</span>
        {{ now }}
      </div>

      <v-spacer />

      <div class="d-flex">
        <v-btn
          v-for="{ link, icon } in social"
          :key="link"
          :href="link"
          class="ml-2"
          color="transparent"
          target="_blank"
          height="34px"
          width="34px"
          depressed
          fab
        >
          <v-icon color="white" size="20px"> {{ icon }} </v-icon>
        </v-btn>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import { getSocial } from "../../../../utils/getSocial";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    now() {
      return new Date().toLocaleString("pt-BR", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>
