var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"navigation-widget",attrs:{"fixed":"","temporary":""},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('router-link',{staticClass:"d-flex px-2 py-4",attrs:{"to":"/"}},[(_vm.informations.img)?_c('img',{staticClass:"d-block mx-auto navbar-brand",attrs:{"src":_vm.files + _vm.informations.img,"alt":_vm.informations.name}}):_vm._e()]),_c('div',{staticClass:"d-flex justify-center mb-4"},[_vm._l((_vm.social),function(item,i){return [(item)?_c('v-btn',{key:i,staticClass:"mr-1 d-flex",attrs:{"href":item.link,"target":"blank","width":"40px","height":"40px","icon":""}},[_c('v-icon',{attrs:{"color":"primary","size":"20"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1):_vm._e()]})],2),_c('PwaButtonWidget'),_c('v-divider'),_c('v-list',{staticClass:"mt-2 px-0",attrs:{"nav":""},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[_vm._l((_vm.menu),function(ref,i){
var text = ref.text;
var to = ref.to;
var dropdown = ref.dropdown;
return [(!dropdown)?_c('v-list-item',_vm._b({key:i,staticClass:"my-0",attrs:{"link":""}},'v-list-item',_vm.getLinkAttrs(to, _vm.server),false),[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold px-4"},[_vm._v(" "+_vm._s(text)+" ")])],1):_c('v-list-group',{key:(i + "d"),staticClass:"dropdown-menu white",attrs:{"value":false,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold px-4"},[_vm._v(" "+_vm._s(text)+" ")])]},proxy:true}],null,true)},_vm._l((dropdown),function(ref,i){
var text = ref.text;
var to = ref.to;
return _c('v-list-item',_vm._b({key:i,staticClass:"pl-12 white mb-0",attrs:{"link":""}},'v-list-item',_vm.getLinkAttrs(to, _vm.server),false),[_c('v-list-item-title',{staticClass:"text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(text)+" ")])],1)}),1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }