var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageTemplate',{class:_vm.componentClass,attrs:{"title":_vm.metaTitle}},[_c('div',{staticClass:"article-custom-view_main"},[_c('div',{staticClass:"article-custom-view_content"},[(_vm.item.call || _vm.parsedTitle || _vm.item.subtitle)?_c('div',{staticClass:"mb-2"},[(_vm.item.call)?_c('div',{staticClass:"d-flex align-center justify-start mb-3"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"black","size":"14"}},[_vm._v(" fa-bookmark ")]),_c('div',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(_vm.item.call)+" ")])],1):_vm._e(),(_vm.parsedTitle)?_c('div',{staticClass:"text-h5 text-sm-h4 font-weight-black"},[_vm._v(" "+_vm._s(_vm.parsedTitle)+" ")]):_vm._e(),(_vm.item.subtitle)?_c('div',{staticClass:"text-body-2 font-weight-bold mt-2"},[_vm._v(" "+_vm._s(_vm.item.subtitle)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center mb-4"},[_c('div',{staticClass:"text-body-2"},[(_vm.item.author)?_c('div',{staticClass:"font-weight-bold grey--text text--darken-2"},[_vm._v(" Por: "+_vm._s(_vm.item.author)+" ")]):_vm._e(),(_vm.parsedDate)?_c('div',[_c('v-icon',{attrs:{"size":"12"}},[_vm._v("fas fa-calendar-alt")]),_vm._v(" "+_vm._s(_vm.parsedDate)+" ")],1):_vm._e()]),_c('ShareButtonWidget')],1),_c('figure',{staticClass:"mb-2"},[(_vm.parsedImg)?_c('img',{attrs:{"src":_vm.parsedImg}}):_vm._e(),(_vm.item.photo_author)?_c('figcaption',{staticClass:"text-body-2 font-weight-bold grey--text text--darken-2"},[_vm._v(" Foto por: "+_vm._s(_vm.item.photo_author)+" ")]):_vm._e()]),(_vm.item.text)?_c('div',{staticClass:"ck-content font-weight-medium mb-4",domProps:{"innerHTML":_vm._s(_vm.item.text)}}):_vm._e(),(_vm.item.video)?_c('VideoSection',{staticClass:"mb-4",attrs:{"link":_vm.item.video}}):_vm._e(),(_vm.videos.length)?_c('div',[_vm._l((_vm.videos),function(ref,keyVideos){
var path = ref.path;
return [_c('div',{key:keyVideos,staticClass:"mb-4"},[_c('video',{staticClass:"d-block",attrs:{"src":_vm.files + path,"controls":"","width":"100%"}})])]})],2):_vm._e(),(_vm.gallerySection.items.length > 1)?_c('GallerySection',_vm._b({attrs:{"no-container":""}},'GallerySection',_vm.gallerySection,false)):_vm._e(),(_vm.audios.length)?_c('AudiosSection',{staticClass:"mb-4",attrs:{"items":_vm.audios,"no-container":""}}):_vm._e(),(_vm.attachments.length)?_c('AttachmentsSection',{staticClass:"mb-4",attrs:{"items":_vm.attachments,"no-container":""}}):_vm._e(),(_vm.item.source)?_c('div',{staticClass:"font-weight-bold grey--text text--darken-2 mb-4"},[_vm._v(" Fonte: "+_vm._s(_vm.item.source)+" ")]):_vm._e()],1),_c('BannerWidget',{attrs:{"category":"Quadrado","type":"col","items-to-show":{
        xs: 6,
        sm: 3,
        md: 6,
        lg: 5,
        xl: 5,
      },"cols":{
        xs: 2,
        sm: 3,
        md: 1,
        lg: 1,
        xl: 1,
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }