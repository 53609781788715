<template>
  <div :class="classNames.navigation" :style="cssVars">
    <a v-ripple @click.prevent="$emit('prev')" :class="classNames.navItemPrev">
      <v-icon :color="iconColor" :size="iconSize"> fa-angle-left </v-icon>
    </a>

    <a v-ripple @click.prevent="$emit('next')" :class="classNames.navItemNext">
      <v-icon :color="iconColor" :size="iconSize"> fa-angle-right </v-icon>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
    positionY: {
      type: Number,
      default: 50,
    },
    inside: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    classNames() {
      const classNames = {
        navigation: [
          "carousel-widget-nav d-flex justify-space-between",
          this.inside && "carousel-widget-nav--inside",
        ],
        navItemPrev: [
          "carousel-widget-nav-item-prev",
          !this.dark ? "secondary" : "white",
          this.disabledPrev && "carousel-widget-nav-item-disabled",
        ],
        navItemNext: [
          "carousel-widget-nav-item-next",
          !this.dark ? "secondary" : "white",
          this.disabledNext && "carousel-widget-nav-item-disabled",
        ],
      };
      return classNames;
    },

    iconSize() {
      return this.mobile ? 20 : 26;
    },
    iconColor() {
      return !this.dark ? "white" : "primary";
    },
    cssVars() {
      const buttonSize = this.mobile ? 32 : 40;

      return {
        "--button-size": `${buttonSize}px`,
        "--icon-size": `${this.iconSize}px`,
        "--position-y": `${this.positionY}%`,
      };
    },
  },
};
</script>

<style lang="scss">
.carousel-widget-nav {
  height: 0;
  position: absolute;
  left: calc(0px - var(--button-size) - 16px + var(--gap));
  right: calc(0px - var(--button-size) - 16px + var(--gap));
  top: calc(var(--position-y) - var(--button-size) / 2);

  &-item-prev,
  &-item-next {
    height: var(--button-size);
    width: var(--button-size);
    border-radius: 50%;
    transition: all 0.1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer !important;
  }

  &-item-disabled {
    filter: grayscale(1);
    opacity: 0.8;
  }

  &-item-prev {
    padding-right: 2px;
  }
  &-item-next {
    padding-left: 2px;
  }
}

.carousel-widget-nav--inside {
  left: calc(var(--gap) + 8px);
  right: calc(var(--gap) + 8px);
}

@media screen and (max-width: 959.9px) {
  .carousel-widget-nav {
    right: calc(var(--gap) + 12px);
    left: calc(var(--gap) + 12px);
  }
}
</style>
