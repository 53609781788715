<template>
  <div>
    <div class="text-body-1 font-weight-black white--text mb-1 ml-1">
      Cidades
    </div>
    <div class="cities-list">
      <div v-for="({ title, slug }, i) in cities" :key="i">
        <v-btn
          :to="'/' + slug"
          class="text-none text-body-2 font-weight-bold px-1"
          color="grey lighten-4"
          text
          small
        >
          {{ title }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cities() {
      return this.$rest("cities").list;
    },
  },
};
</script>

<style lang="scss" scoped>
.cities-list {
  column-count: 2;
}
</style>
