<template>
  <v-navigation-drawer
    class="navigation-widget"
    v-model="value"
    fixed
    temporary
  >
    <router-link to="/" class="d-flex px-2 py-4">
      <img
        v-if="informations.img"
        :src="files + informations.img"
        :alt="informations.name"
        class="d-block mx-auto navbar-brand"
      />
    </router-link>

    <div class="d-flex justify-center mb-4">
      <template v-for="(item, i) in social">
        <v-btn
          v-if="item"
          :key="i"
          :href="item.link"
          target="blank"
          class="mr-1 d-flex"
          width="40px"
          height="40px"
          icon
        >
          <v-icon color="primary" size="20">
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </template>
    </div>

    <PwaButtonWidget />

    <v-divider />

    <v-list v-model="current" class="mt-2 px-0" nav>
      <template v-for="({ text, to, dropdown }, i) in menu">
        <v-list-item
          v-if="!dropdown"
          class="my-0"
          :key="i"
          v-bind="getLinkAttrs(to, server)"
          link
        >
          <v-list-item-title class="text-body-2 font-weight-bold px-4">
            {{ text }}
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          v-else
          :key="`${i}d`"
          :value="false"
          class="dropdown-menu white"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-title class="text-body-2 font-weight-bold px-4">
              {{ text }}
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="({ text, to }, i) in dropdown"
            :key="i"
            v-bind="getLinkAttrs(to, server)"
            class="pl-12 white mb-0"
            link
          >
            <v-list-item-title class="text-body-2 font-weight-bold">
              {{ text }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getLinkAttrs } from "../../utils/getLinkAttrs";
import { formatToURL } from "../../utils/formatToURL";
import { getSocial } from "../../utils/getSocial";
import PwaButtonWidget from "./PwaButtonWidget.vue";

/**
 * Instancie esse componente na App.vue
 * e use a função window.showNavigationDrawer para mostrar o menu lateral.
 */
export default {
  data() {
    return {
      current: this.$route.path,
      value: false,
    };
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    newsCategories() {
      return this.$rest("news-categories").list;
    },
    cities() {
      return this.$rest("cities").list;
    },
    menu() {
      return [
        { text: "Início", to: "/inicio" },
        { text: "Sobre Nós", to: "/sobre" },
        {
          text: "Categorias",
          dropdown: this.newsCategories.map(({ title }) => ({
            text: title,
            to: "/" + formatToURL(title),
          })),
        },
        {
          text: "Cidades",
          dropdown: this.cities.map(({ title }) => ({
            text: title,
            to: "/" + formatToURL(title),
          })),
        },
        { text: "Últimas", to: "/noticias" },
        { text: "Variedades", to: "/variedades" },
        { text: "Artigos", to: "/artigos" },
        { text: "Eventos", to: "/galerias" },
        { text: "Informes Publicitários", to: "/informe-publicitario" },
        { text: "Vídeos", to: "/videos" },
        { text: "Contato", to: "/contato" },
      ];
    },
  },
  methods: {
    show() {
      this.value = !this.value;
    },
    getLinkAttrs,
  },
  created() {
    window.showNavigationDrawer = () => this.show();
  },
  components: { PwaButtonWidget },
};
</script>

<style lang="scss" scoped>
.navigation-widget {
  .navbar-brand {
    width: 90%;
  }
}

.dropdown-menu::v-deep {
  .v-icon {
    font-size: 14px !important;
  }

  .v-list-group__header {
    margin-bottom: 0 !important;
    margin-top: 8px;
  }

  .v-list-group__items {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
</style>
