<template>
  <div :class="wrapClass">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :class="buttonClass"
      :color="dark ? 'white' : 'primary'"
      height="48px"
      rounded
      depressed
      :type="type"
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    dark: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
  },
  computed: {
    wrapClass() {
      const alignClassOptions = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      const wrapClass = ["d-flex", alignClassOptions[this.align]];

      return wrapClass;
    },
    buttonClass() {
      const buttonClass = [
        "button-widget text-button px-8",
        this.dark && "primary--text",
      ];

      return buttonClass;
    },
  },
};
</script>
