<template>
  <v-btn
    v-show="!hidden"
    class="pwa-btn-widget text-caption text-none wrap pa-2 justify-center"
    @click="installPwa"
    id="install-app"
    color="primary"
    height="48px"
    depressed
    tile
  >
    <img
      v-if="informations.icon"
      class="d-block mr-2 rounded white"
      width="32px"
      height="32px"
      :src="files + informations.icon"
    />
    <span class="pwa-btn-widget_text white--text text-body-1">
      Instalar o App
    </span>
  </v-btn>
</template>

<script>
export default {
  data: () => ({
    hidden: true,
    installPrompt: null,
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  methods: {
    beforeInstallPrompt(event) {
      this.installPrompt = event;
      this.hidden = false;
    },
    installPwa() {
      if (this.installPrompt) {
        this.installPrompt.prompt();

        this.installPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("Usuário aceitou instalar o PWA");
          } else {
            console.log("Usuário recusou instalar o PWA");
          }

          this.installPrompt = null;
        });
      }
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", this.beforeInstallPrompt);
  },
  beforeDestroy() {
    window.removeEventListener("beforeinstallprompt", this.beforeInstallPrompt);
  },
};
</script>

<style lang="scss">
.pwa-btn-widget {
  width: 100%;

  img {
    border: 1px solid white !important;
    display: block;
  }

  &_text {
    white-space: normal;
    font-size: 16px;
  }
}
</style>
