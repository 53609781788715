<template>
  <PageTemplate :class="componentClass" :title="metaTitle">
    <div class="article-custom-view_main">
      <div class="article-custom-view_content">
        <div v-if="item.call || parsedTitle || item.subtitle" class="mb-2">
          <div v-if="item.call" class="d-flex align-center justify-start mb-3">
            <v-icon class="mr-1" color="black" size="14"> fa-bookmark </v-icon>

            <div class="text-caption font-weight-bold">
              {{ item.call }}
            </div>
          </div>

          <div v-if="parsedTitle" class="text-h5 text-sm-h4 font-weight-black">
            {{ parsedTitle }}
          </div>
          <div v-if="item.subtitle" class="text-body-2 font-weight-bold mt-2">
            {{ item.subtitle }}
          </div>
        </div>

        <div class="d-flex justify-space-between align-center mb-4">
          <div class="text-body-2">
            <div
              v-if="item.author"
              class="font-weight-bold grey--text text--darken-2"
            >
              Por: {{ item.author }}
            </div>
            <div v-if="parsedDate">
              <v-icon size="12">fas fa-calendar-alt</v-icon>
              {{ parsedDate }}
            </div>
          </div>
          <ShareButtonWidget />
        </div>

        <figure class="mb-2">
          <img v-if="parsedImg" :src="parsedImg" />
          <figcaption
            v-if="item.photo_author"
            class="text-body-2 font-weight-bold grey--text text--darken-2"
          >
            Foto por: {{ item.photo_author }}
          </figcaption>
        </figure>

        <div
          v-if="item.text"
          class="ck-content font-weight-medium mb-4"
          v-html="item.text"
        ></div>

        <VideoSection v-if="item.video" class="mb-4" :link="item.video" />

        <div v-if="videos.length">
          <template v-for="({ path }, keyVideos) in videos">
            <div :key="keyVideos" class="mb-4">
              <video
                class="d-block"
                :src="files + path"
                controls
                width="100%"
              ></video>
            </div>
          </template>
        </div>

        <GallerySection
          v-if="gallerySection.items.length > 1"
          v-bind="gallerySection"
          no-container
        />

        <AudiosSection
          v-if="audios.length"
          class="mb-4"
          :items="audios"
          no-container
        />

        <AttachmentsSection
          v-if="attachments.length"
          class="mb-4"
          :items="attachments"
          no-container
        />

        <div
          v-if="item.source"
          class="font-weight-bold grey--text text--darken-2 mb-4"
        >
          Fonte: {{ item.source }}
        </div>
      </div>
      <BannerWidget
        category="Quadrado"
        type="col"
        :items-to-show="{
          xs: 6,
          sm: 3,
          md: 6,
          lg: 5,
          xl: 5,
        }"
        :cols="{
          xs: 2,
          sm: 3,
          md: 1,
          lg: 1,
          xl: 1,
        }"
      />
    </div>
  </PageTemplate>
</template>

<script>
import GallerySection from "../components/sections/GallerySection.vue";
import VideoSection from "../components/sections/VideoSection.vue";
import AudiosSection from "../components/sections/AudiosSection.vue";
import AttachmentsSection from "../components/sections/AttachmentsSection.vue";
import BannerWidget from "../components/widgets/BannerWidget.vue";
import PageTemplate from "../components/templates/PageTemplate.vue";
import ShareButtonWidget from "../components/widgets/ShareButtonWidget.vue";
import { formatDate } from "../utils/formatDate";

/**
 * $route.meta = {
 *   mod: string; // Chave do módulo.
 *   ?title: string | (component) => string; // Título da página.
 *   ?params: Record<string, string> | (component) => Record<string, string>; // Parametros da requisição de dados
 *   ?fullWidth: boolean // Se tiver essa propriedade, o template vai ficar 100% do container.
 * }
 */
export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    metaTitle() {
      const metaTitle = this.$route.meta.title;

      if (!metaTitle) {
        return this.$route.name;
      }

      if (typeof metaTitle === "function") {
        return metaTitle(this);
      } else {
        return metaTitle;
      }
    },
    mod() {
      return this.$route.meta.mod;
    },
    fullWidth() {
      return this.$route.meta.fullWidth;
    },
    itemId() {
      return this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1");
    },
    item() {
      return this.$rest(this.mod).item;
    },
    parsedTitle() {
      return this.item.title?.replace(/\\"/g, '"');
    },
    parsedImg() {
      const path = this.item.img?.featured?.path;

      return path ? this.files + path + "?resize=1&w=600" : null;
    },
    parsedDate() {
      let options = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      if (this.mod === "news")
        options = {
          ...options,
          hour: "numeric",
          minute: "numeric",
        };

      return this.item.date ? formatDate(this.item.date, options) : null;
    },
    gallerySection() {
      const gallerySection = {};

      gallerySection.items = this.item.img?.list || [];
      gallerySection.items = gallerySection.items.map(({ path }) => ({
        src: this.files + path,
      }));

      gallerySection.cols = this.fullWidth
        ? {
            xs: 2,
            sm: 3,
            md: 3,
            lg: 4,
            xl: 4,
          }
        : {
            xs: 2,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          };

      return gallerySection;
    },
    audios() {
      return this.item.audio?.list || [];
    },
    attachments() {
      return this.item.files?.list || [];
    },
    videos() {
      return this.item.videos?.list || [];
    },
    componentClass() {
      return this.fullWidth && "article-custom-view_full";
    },
  },
  methods: {
    verifyInstagram() {
      window.instgrm?.Embeds.process();
    },
    putView() {
      fetch(`${this.server}/rest/${this.mod}/${this.itemId}/views`, {
        method: "PUT",
      });
    },
  },
  watch: {
    itemId() {
      this.$rest(this.mod)
        .get({ id: this.itemId })
        .catch(() => this.$router.replace({ path: "/erro404" }));
    },
  },
  mounted() {
    this.$rest(this.mod)
      .get({ id: this.itemId })
      .then(() => {
        this.verifyInstagram();

        if (
          ["news", "articles", "varieties", "galleries", "videos"].includes(
            this.mod
          )
        )
          this.putView();
      })
      .catch(() => this.$router.replace({ path: "/erro404" }))
      .finally(() => (this.loading = false));
  },
  components: {
    BannerWidget,
    GallerySection,
    VideoSection,
    AudiosSection,
    AttachmentsSection,
    PageTemplate,
    ShareButtonWidget,
  },
};
</script>

<style lang="scss">
.article-custom-view_main {
  display: grid;
  grid-template-columns: 600px 300px;
  justify-content: center;
  gap: 16px;
}

.article-custom-view_full .article-custom-view_main {
  grid-template-columns: 3fr 1fr;
}

.article-custom-view_content {
  img {
    width: 100%;
    border-radius: 12px;
  }

  iframe,
  .instagram-media {
    max-width: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 1264px) {
  .article-custom-view_main {
    grid-template-columns: 2fr 1fr !important;
  }
}
@media screen and (max-width: 959.9px) {
  .article-custom-view_main {
    grid-template-columns: 1fr !important;
  }
}
</style>
