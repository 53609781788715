<template>
  <div>
    <div class="text-body-1 font-weight-black white--text mb-1 ml-1">Menu</div>
    <div class="menu-list">
      <div v-for="({ title, to }, i) in menu" :key="i">
        <v-btn
          :to="to"
          class="text-none text-body-2 font-weight-bold px-1"
          color="grey lighten-4"
          text
          small
        >
          {{ title }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menu: [
      { title: "Início", to: "/inicio" },
      { title: "Sobre Nós", to: "/sobre" },
      { title: "Artigos", to: "/artigos" },
      { title: "Variedades", to: "/variedades" },
      { title: "Eventos", to: "/galerias" },
      { title: "Contato", to: "/contato" },
    ],
  }),
};
</script>
