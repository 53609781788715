<template>
  <div :class="classNames.component">
    <component :is="containerTagComponent" :class="classNames.container">
      <TitleWidget
        v-if="title"
        class="mb-4"
        :dark="dark"
        :align="titleAlign"
        :link="titleLink"
      >
        {{ title }}
      </TitleWidget>

      <LoadingWidget v-if="loading" />

      <v-alert
        v-else-if="noData"
        class="mx-auto"
        type="info"
        color="grey"
        max-width="720px"
      >
        {{ noDataText }}
      </v-alert>

      <template v-else>
        <slot />
      </template>
    </component>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import { VContainer } from "vuetify/lib/components";

export default {
  props: {
    title: String,
    titleAlign: {
      type: String,
      default: "center",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    titleLink: {
      type: String,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "Nenhum conteúdo adicionado até o momento.",
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classNames() {
      const classNames = {
        component: ["section-template", this.dark && "secondary"],
        container: [
          !this.noContainer && !this.noPadding ? "py-8 py-lg-15" : "py-0",
        ],
      };

      return classNames;
    },
    containerTagComponent() {
      return this.noContainer ? "div" : "v-container";
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    VContainer,
  },
};
</script>

<style>
.section-template.secondary p {
  color: #eee;
}
</style>
