<template>
  <div class="mx-n3">
    <div class="d-flex flex-column items">
      <v-btn
        v-for="({ title, path }, keyItems) in items"
        :key="keyItems"
        class="justify-start text-none"
        color="grey darken-2"
        :href="files + path"
        target="_blank"
        block
        text
      >
        <v-icon size="20" left> fa-file-pdf </v-icon>
        {{ title }}
      </v-btn>
    </div>
  </div>
</template>

<script>
const _data = {
  title: "Anexos",
};

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    ..._data,
  }),
};
</script>

<style lang="scss" scoped>
.items {
  gap: 15px;
}
</style>
