<template>
  <div class="header">
    <Top />

    <v-sheet class="logo" color="white">
      <v-container class="py-5">
        <BannerWidget class="mb-5" category="Top 1 (1180x190)" />

        <div class="header-center">
          <a href="/">
            <img
              v-if="informations.img"
              :src="files + informations.img"
              :alt="informations.name"
              class="d-block"
              height="120px"
            />
          </a>

          <BannerWidget category="Top 2 (728x90)" />
        </div>
      </v-container>
    </v-sheet>

    <Menu />
  </div>
</template>

<script>
import Top from "./Top.vue";
import Menu from "./Menu.vue";
import BannerWidget from "../../../widgets/BannerWidget.vue";

export default {
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
  },
  components: {
    Top,
    Menu,
    BannerWidget,
  },
};
</script>

<style lang="scss" scoped>
.header::v-deep {
  .v-toolbar__content {
    align-items: initial;
    flex-direction: column;
    padding: 0;
  }

  .menu {
    border-top: 1px solid #ccc !important;

    &.bordered {
      border-bottom: 1px solid #ccc !important;
    }
  }
}

.header-center {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: max-content calc(100% - 450px);
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: 1264px) {
    grid-template-columns: auto 145px;
    gap: 16px;

    > :nth-child(2) {
      grid-column: 1 / -1;
      grid-row-start: 2;
      margin-left: auto;
      margin-right: auto;
      width: 728px;
    }
  }
}
</style>
