<template>
  <div
    class="news-card-container"
    :class="{
      'news-card--detail': detail && type === 'cover',
    }"
    :style="cssVars"
  >
    <v-card :class="cardClass" :to="to" flat>
      <picture :class="{ white: detail && type === 'cover' }">
        <v-btn
          v-if="!hideCategory && tagTitle"
          :to="tagLink"
          class="news-card_category font-weight-bold"
          color="primary"
          depressed
          small
        >
          {{ tagTitle }}
        </v-btn>

        <div
          v-if="type !== 'cover' && call && !hideCall"
          class="white px-2 news-card_call"
          color="transparent"
        >
          <v-icon class="mr-1" color="primary" size="14"> fa-bookmark </v-icon>

          <span class="primary--text text-caption font-weight-bold">
            {{ call }}
          </span>
        </div>
        <img
          @error="onError"
          loading="lazy"
          :src="parsedImg"
          :alt="title"
          width="100%"
        />
      </picture>

      <div class="news-card_title">
        <div
          v-if="type === 'cover' && call && !hideCall"
          class="d-flex justify-start mb-2"
        >
          <div class="d-flex align-center white pa-2" color="transparent">
            <v-icon class="mr-1" color="primary" size="14">
              fa-bookmark
            </v-icon>

            <span class="primary--text text-caption font-weight-bold">
              {{ call }}
            </span>
          </div>
        </div>

        {{ parsedTitle }}
        <br />
        <div
          v-if="!hideDate && parsedDate"
          class="d-inline-flex align-center font-weight-medium"
        >
          <v-icon size="12" color="inherit" left>fas fa-calendar-alt</v-icon>
          {{ parsedDate }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { formatToURL } from "../../utils/formatToURL";
import { formatDate } from "../../utils/formatDate";
import DefaultImg from "../../assets/img/default-img.png";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      default: DefaultImg,
    },
    title: {
      type: String,
      required: true,
    },
    call: {
      type: String,
    },
    category: {
      type: [Object, String], // { id: Number, title: String }
    },
    city: {
      type: [Object, String], // { id: Number, title: String }
    },
    date: {
      type: String,
    },
    type: {
      type: String,
      validator: (val) => ["vertical", "horizontal", "cover"].includes(val),
      default: "vertical",
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    detail: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: Number,
    },
    basePath: {
      type: String,
      default: "/noticias",
    },
    hideCategory: {
      type: Boolean,
      default: false,
    },
    hideCall: {
      type: Boolean,
      default: false,
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardClass() {
      return [
        "news-card transparent",
        !this.dark ? "news-card--light" : "news-card--dark",
        "news-card--" + this.type,
        this.aspectRatio < 1 ? "news-card--portrait" : "news-card--landscape",
        this.tile && "news-card--tile",
      ];
    },
    cssVars() {
      return {
        "--aspect-ratio": this.aspectRatio,
      };
    },
    parsedTitle() {
      return this.title?.replace(/\\"/g, '"');
    },
    parsedImg() {
      let img = "";

      if (!/^https?:\/\//.test(this.img)) {
        img = this.files;
      }

      img += this.img;

      const width = this.type === "cover" ? 1000 : 400;

      return `${img}?resize=1&w=${width}`;
    },
    parsedDate() {
      return this.date ? formatDate(this.date) : null;
    },
    to() {
      const slug = formatToURL(this.title);

      return this.basePath + `/${slug}-${this.id}`;
    },
    tagTitle() {
      let title;

      if (this.city) {
        if (typeof this.city === "string") {
          title = this.city;
        }

        title = this.city?.title;
      }

      if (this.category) {
        if (typeof this.category === "string") {
          title = this.category;
        }

        title = this.category?.title;
      }

      return title;
    },
    tagLink() {
      let link;

      if (this.category) {
        if (typeof this.category === "string") {
          return null;
        }

        const categoryData = this.$rest("news-categories").list.find(
          ({ id }) => id === this.category.id
        );

        link = categoryData ? "/" + categoryData.slug : null;
      }

      if (this.city) {
        if (typeof this.city === "string") {
          return null;
        }

        const cityData = this.$rest("cities").list.find(
          ({ id }) => id === this.city.id
        );

        link = cityData ? "/" + cityData.slug : null;
      }

      return link;
    },
  },
  methods: {
    onError(e) {
      e.target.src = DefaultImg;
    },
  },
};
</script>

<style lang="scss">
@property --overlay-color {
  syntax: "<color>";
  initial-value: rgba(0, 0, 0, 0);
  inherits: false;
}

.news-card--light {
  --title-color: #000000;
  --hover-title-color: var(--v-primary-darken1);
  --background-color: var(--v-primary-base);
  --dot-color: var(--v-primary-base);
}

.news-card--dark {
  --title-color: #fff;
  --hover-title-color: #ccc;
  --background-color: #fff;
  --dot-color: var(--v-primary-base);
}

.news-card--tile {
  --radius: 0px;
}

.news-card-container {
  container-name: news-card;
  container-type: inline-size;
}

.news-card {
  border-radius: var(--radius, 12px) !important;
  display: grid !important;
  grid-auto-flow: row;
  overflow: hidden;
  z-index: 2;

  img {
    object-fit: cover;
    object-position: center;
    transition: 0.2s;
  }

  picture {
    display: flex;
    position: relative;
    overflow: hidden;
  }

  picture::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color: #000000, $alpha: 0);
    width: 100%;
    height: 100%;
    transition: 0.3s;
    border-radius: var(--radius, 12px);
  }

  &_title {
    font-size: 16px !important;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.15px !important;
    font-family: "Raleway", sans-serif !important;
    transition: 0.3s;
    color: var(--title-color);

    i {
      color: inherit !important;
    }
  }

  &_category {
    position: absolute;
    top: 8px;
    left: 8px;
    letter-spacing: 0.5px;
    z-index: 20;
  }

  &_call {
    bottom: 0;
    left: 0;
    position: absolute;
  }

  @container news-card (max-width: 300px) {
    &_title {
      font-size: 14px !important;
    }
  }
}

.news-card--horizontal {
  grid-template-columns: max-content auto;
  gap: 8px;

  img {
    aspect-ratio: var(--aspect-ratio, calc(4 / 3));
    max-height: 100px;
  }

  picture:hover::after {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .news-card_title:hover {
    color: var(--hover-title-color);
  }

  @container news-card (max-width: 300px) {
    img {
      display: none !important;
    }

    .news-card_title::before {
      display: inline-block;
      content: "";
      background-color: var(--dot-color);
      margin-right: 4px;
      margin-bottom: 3px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }

  @container news-card (min-width: 301px) {
    img {
      border-radius: var(--radius, 12px) !important;
    }
  }
}

.news-card--vertical {
  align-content: flex-start;

  picture {
    aspect-ratio: var(--aspect-ratio, 1.618);
  }

  img {
    border-radius: var(--radius, 12px);
    aspect-ratio: var(--aspect-ratio, 1.618);
  }

  .news-card_title {
    padding-bottom: 8px;
  }

  @container news-card (max-width: 250px) {
    gap: 8px;

    .news-card_title:hover {
      color: var(--hover-title-color);
    }
  }

  @container news-card (min-width: 251px) {
    .news-card_title {
      background-color: var(--background-color);
      color: white;
      padding: 8px 12px;
      height: 94px;
    }

    picture::after,
    img {
      border-radius: var(--radius, 12px) 12px 0px 0px !important;
    }
  }

  @container news-card (min-width: 301px) {
    .news-card_title {
      padding: 12px;
    }
  }

  @container news-card (max-width: 499px) {
    picture:hover::after {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }
  }
}

.news-card--detail {
  position: relative;

  @container news-card (min-width: 300px) {
    &:before,
    &:after {
      content: "";
      pointer-events: none;
      position: absolute;
    }

    &:before {
      background-color: var(--v-primary-darken2);
      border-radius: 16px 0 0 0;
      height: 120px;
      left: -3px;
      top: -3px;
      width: 120px;
    }

    &:after {
      background-color: var(--v-primary-darken2);
      border-radius: 0 0 16px 0;
      height: 120px;
      bottom: -3px;
      right: -3px;
      width: 120px;
      z-index: 1;
    }
  }
}

.news-card--cover {
  border-radius: var(--radius, 12px) !important;
  position: relative;

  picture::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.7) 80%,
      rgba(0, 0, 0, 0.9) 100%
    );
    width: 100%;
    height: 100%;
    z-index: 8;
  }

  picture::after {
    background: radial-gradient(transparent 50%, var(--overlay-color) 100%);
    transition: --overlay-color 0.3s;
    border-radius: 0 !important;
    z-index: 9;
  }

  &:hover {
    picture::after {
      --overlay-color: rgba(0, 0, 0, 0.6);
    }

    img {
      transform: scale(1.1);
    }
  }

  img {
    aspect-ratio: var(--aspect-ratio, 1.618);
  }

  &.news-card--portrait {
    .news-card_title {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 12px;
      background-color: transparent;
      font-size: 22px !important;
      z-index: 10;
    }

    @container news-card (max-width: 420px) {
      .news-card_title {
        font-size: 18px !important;
      }
    }

    @container news-card (max-width: 240px) {
      .news-card_title {
        font-size: 16px !important;
      }
    }
  }

  &.news-card--landscape {
    @container news-card (max-width: 300px) {
      picture {
        border-radius: var(--radius, 12px) !important;
        margin-bottom: 8px;
      }
    }

    @container news-card (min-width: 301px) {
      .news-card_title {
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 12px;
        background-color: transparent;

        z-index: 10;
      }
    }
  }

  @container news-card (min-width: 421px) {
    .news-card_title {
      font-size: 22px !important;
    }
  }

  @container news-card (max-width: 420px) {
    .news-card_title {
      font-size: 16px !important;
    }
  }

  @container news-card (max-width: 240px) {
    .news-card_title {
      font-size: 14px !important;
    }
  }
}
</style>
