<template>
  <iframe
    class="video-section"
    width="560"
    height="315"
    :src="iframeSrc"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
</template>

<script>
import { getYoutubeCode } from "../../utils/getYoutubeCode";

export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
  computed: {
    iframeSrc() {
      return `https://www.youtube.com/embed/${getYoutubeCode(this.link)}`;
    },
  },
};
</script>

<style>
.video-section {
  width: 100%;
  aspect-ratio: 1.618;
  border-radius: 12px;
}
</style>
