<template>
  <div v-if="banners.length" :class="componentClass" :style="componentStyle">
    <CarouselWidget
      v-if="type === 'carousel'"
      :items="banners"
      :items-to-show="itemsToShow"
      :play-speed="10000"
      :touch-drag="false"
      :mouse-drag="false"
    >
      <template #item="{ item }">
        <a class="banner-widget_item d-flex" :href="item.link" target="_blank">
          <img :src="item.img" :alt="item.title" />
        </a>
      </template>
    </CarouselWidget>

    <div v-else>
      <a
        v-for="(item, i) in banners"
        :key="i"
        class="banner-widget_item d-flex mb-1"
        :href="item.link"
        target="_blank"
      >
        <img :src="item.img" :alt="item.title" />
      </a>
    </div>
  </div>
</template>

<script>
import CarouselWidget from "./CarouselWidget/Index.vue";

export default {
  props: {
    category: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "carousel",
      validator: (val) => ["carousel", "row", "col"].includes(val),
    },
    placeholder: {
      type: Boolean,
      default: true,
    },
    itemsToShow: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
      default: () => ({ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }),
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
  },
  computed: {
    banners() {
      if (!this.categoryData) {
        return [];
      }

      const banners = this.$rest("banners").list.map((item) => {
        const newItem = { ...item };
        newItem.img = this.files + newItem.img;

        return newItem;
      });

      const filteredBanners = banners
        .filter(({ category }) => category?.title == this.category)
        .slice(0, this.itemsToShow[this.$vuetify.breakpoint.name]);

      if (!filteredBanners.length && this.placeholder) {
        const { width, height } = this.categoryData;

        return [
          { img: `https://placehold.co/${width}x${height}/777777/FFFFFF/png` },
        ];
      }

      return filteredBanners;
    },
    categories() {
      return this.$rest("banners-categories").list;
    },
    categoryData() {
      return this.categories.find(({ title }) => title === this.category);
    },
    componentClass() {
      return ["banner-widget", `banner-widget--${this.type}`];
    },
    parsedCols() {
      return this.cols ? this.cols : this.itemsToShow;
    },
    componentStyle() {
      if (!this.categoryData) {
        return null;
      }

      const { width, height } = this.categoryData;

      return {
        "--aspect-ratio": width / height,
        "--max-width": width + "px",
        "--max-height": height + "px",
        "--cols": this.parsedCols[this.$vuetify.breakpoint.name],
      };
    },
  },
  created() {
    if (!this.banners.length) {
      this.$rest("banners").get();
    }

    if (!this.categories.length) {
      this.$rest("banners-categories").get();
    }
  },
  components: {
    CarouselWidget,
  },
};
</script>

<style lang="scss">
.banner-widget_item {
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    max-width: var(--max-width);
    aspect-ratio: var(--aspect-ratio);
    object-fit: contain;
    background-color: #ccc;
  }
}
.banner-widget--carousel {
  max-width: 100vw;
  overflow: hidden;
}

.banner-widget--col {
  > div {
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
  }

  img {
    aspect-ratio: unset !important;
  }
}

.banner-widget--row {
  > div {
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
    justify-content: center;
    gap: 12px;
  }

  img {
    aspect-ratio: unset !important;
    height: var(--max-height) !important;
    max-width: auto !important;
  }
}
</style>
