<template>
  <SectionTemplate
    class="gallery-section"
    title="Galeria"
    title-align="left"
    :no-container="noContainer"
  >
    <GridWidget :cols="cols">
      <picture v-for="({ src }, i) in items" :key="i" @click="showGallery(i)">
        <img :src="src + '?resize=1&w=400'" />
      </picture>
    </GridWidget>
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "../templates/SectionTemplate.vue";
import GridWidget from "../widgets/GridWidget.vue";

export default {
  props: {
    items: {
      type: Array, // { src }[]
      default: () => [],
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
  },
  computed: {
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    showGallery(index) {
      window.showGalleryWidget(this.items, index);
    },
  },
  components: {
    SectionTemplate,
    GridWidget,
  },
};
</script>

<style lang="scss">
.gallery-section {
  img {
    display: flex;
    width: 100%;
    aspect-ratio: 1.618;
    object-fit: cover;
    position: relative;
  }

  picture {
    position: relative;
  }

  picture::after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s;
    cursor: pointer;
  }

  picture:hover::after {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
</style>
