export function getLinkAttrs(link, base) {
  const attrs = {};

  if (/https?:\/\//.test(link)) {
    if (link.includes(base)) {
      attrs.to = link.replace(base, "");
    } else {
      attrs.href = link;
      attrs.target = "_blank";
    }
  } else {
    attrs.to = link;
  }

  return attrs;
}
