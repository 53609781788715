import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ListCustomView from "../views/ListCustomView.vue";
import ArticleCustomView from "../views/ArticleCustomView.vue";
import ContactView from "../views/ContactView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import Error404View from "../views/Error404View.vue";
import NewsCard from "../components/cards/NewsCard.vue";
import VideoCard from "../components/cards/VideoCard.vue";
import ElectionCountView from "../views/ElectionCountView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "",
    path: "/",
    component: HomeView,
  },
  {
    name: "Apuração das Eleições 2024",
    path: "/apuracao-das-eleicoes-2024",
    component: ElectionCountView,
  },
  {
    name: "Sobre Nós",
    path: "/sobre",
    component: AboutView,
  },
  {
    name: "Notícias",
    path: "/noticias",
    component: ListCustomView,
    meta: {
      mod: "news",
      cardComponent: NewsCard,
    },
  },
  {
    name: "Notícia",
    path: "/noticias/:item",
    component: ArticleCustomView,
    meta: {
      mod: "news",
    },
  },
  {
    name: "Notícia",
    path: "/noticias/:item/",
    component: ArticleCustomView,
    meta: {
      mod: "news",
    },
  },
  {
    name: "Busca",
    path: "/busca",
    component: ListCustomView,
    meta: {
      mod: "news",
      basePath: "/noticias",
      cardComponent: NewsCard,
      params: (component) => ({
        search: component.$route.query.q,
      }),
    },
  },
  {
    name: "Mais Acessadas",
    path: "/mais-acessadas",
    component: ListCustomView,
    meta: {
      mod: "news",
      cardComponent: NewsCard,
      params: {
        orderBy: "views",
      },
    },
  },
  {
    name: "Artigos",
    path: "/artigos",
    component: ListCustomView,
    meta: {
      mod: "articles",
      cardComponent: NewsCard,
    },
  },
  {
    name: "Artigo",
    path: "/artigos/:item",
    component: ArticleCustomView,
    meta: {
      mod: "articles",
    },
  },
  {
    name: "Variedades",
    path: "/variedades",
    component: ListCustomView,
    meta: {
      mod: "varieties",
      cardComponent: NewsCard,
    },
  },
  {
    name: "Variedade",
    path: "/variedades/:item",
    component: ArticleCustomView,
    meta: {
      mod: "varieties",
    },
  },
  {
    name: "Eventos",
    path: "/galerias",
    component: ListCustomView,
    meta: {
      mod: "galleries",
      cardComponent: NewsCard,
    },
  },
  {
    name: "Evento",
    path: "/galerias/:item",
    component: ArticleCustomView,
    meta: {
      mod: "galleries",
      fullWidth: true,
    },
  },
  {
    name: "Informes Publicitários",
    path: "/informe-publicitario",
    component: ListCustomView,
    meta: {
      mod: "videos",
      cardComponent: VideoCard,
      params: {
        ads_report: 1,
      },
      cols: { xs: 1, sm: 2, lg: 3 },
    },
  },
  {
    name: "Informe Publicitário",
    path: "/informe-publicitario/:item",
    component: ArticleCustomView,
    meta: {
      mod: "videos",
    },
  },
  {
    name: "Vídeos",
    path: "/videos",
    component: ListCustomView,
    meta: {
      mod: "videos",
      cardComponent: VideoCard,
      params: {
        ads_report: 0,
      },
      cols: { xs: 1, sm: 2, lg: 3 },
    },
  },
  {
    name: "Vídeo",
    path: "/videos/:item",
    component: ArticleCustomView,
    meta: {
      mod: "videos",
    },
  },
  {
    name: "Contato",
    path: "/contato",
    component: ContactView,
  },
  {
    name: "Contato - Obrigado!",
    path: "/contato/obrigado",
    component: ContactResponseView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "/:item",
    name: "Notícias",
    component: ListCustomView,
    meta: {
      mod: "news",
      title(component) {
        const category = component
          .$rest("news-categories")
          .list.find((v) => v.slug == component.$route.params.item);

        const city = component
          .$rest("cities")
          .list.find((v) => v.slug == component.$route.params.item);

        if (category) {
          return category.title;
        } else if (city) {
          return city.title;
        } else {
          return component.$route.name;
        }
      },
      basePath: "/noticias",
      cardComponent: NewsCard,
      params: (component) => ({
        tag: component.$route.params.item,
      }),
    },
  },
  {
    path: "*",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
