<template>
  <div v-if="count > 1" :class="classNames.container">
    <div
      v-for="i in count"
      @click="$emit('change', i)"
      v-ripple
      :class="classNames.item(i)"
      :key="i"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      required: true,
      validator: (val) => val > 0,
    },
    actived: {
      type: Number,
      required: true,
      validator: (val) => val > 0,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classNames() {
      const classNames = {
        container: [
          "carousel-widget-dots d-flex justify-center mt-2 mt-md-4",
          this.dark && "carousel-widget-dots_dark",
        ],
        item: (i) => [
          "carousel-widget-dots_item",
          i == this.actived && "active",
        ],
      };

      return classNames;
    },
  },
};
</script>

<style lang="scss">
.carousel-widget-dots {
  gap: 8px;

  &_item {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    cursor: pointer;
    height: 10px;
    transition: 0.3s;
    width: 10px;

    &.active {
      background-color: var(--v-primary-base);
    }
  }
}

.carousel-widget-dots_dark {
  .carousel-widget-dots_item {
    background-color: rgba(255, 255, 255, 0.6);

    &.active {
      background-color: white !important;
    }
  }
}
</style>
