<template>
  <SectionTemplate
    v-bind="{
      title,
      dark,
      loading,
      noData,
      noDataText,
      titleAlign,
      noContainer,
    }"
  >
    <ListWidget :count-pages="countPages" :cols="cols" :gap="gap">
      <slot></slot>
    </ListWidget>
  </SectionTemplate>
</template>

<script>
import SectionTemplate from "./SectionTemplate.vue";
import ListWidget from "../widgets/ListWidget.vue";

export default {
  props: {
    title: String,
    dark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noData: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "Nenhum conteúdo adicionado até o momento.",
    },
    titleAlign: {
      type: String,
      default: "center",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    countPages: {
      type: [String, Number],
    },
    noContainer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SectionTemplate,
    ListWidget,
  },
};
</script>
