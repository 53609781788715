<template>
  <div>
    <v-btn
      v-for="({ text, link, icon }, i) in social"
      :key="i"
      class="ml-2"
      :href="link"
      target="_blank"
      min-width="auto"
      color="white"
      :title="text"
      depressed
      rounded
      icon
    >
      <v-icon size="24">{{ icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { getSocial } from "../../../utils/getSocial";

export default {
  data: () => ({
    year: new Date().getFullYear(),
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },

    social() {
      return getSocial(this.informations);
    },
  },
};
</script>

<style lang="scss"></style>
