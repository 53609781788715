<template>
  <PageTemplate :title="about.title" :cover="cover" no-container>
    <SectionTemplate>
      <div v-html="about.text"></div>
    </SectionTemplate>
  </PageTemplate>
</template>

<script>
import PageTemplate from "../components/templates/PageTemplate.vue";
import SectionTemplate from "../components/templates/SectionTemplate.vue";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    about() {
      return this.$rest("about").item;
    },
  },
  beforeCreate() {
    this.$rest("about")
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  components: {
    PageTemplate,
    SectionTemplate,
  },
};
</script>
