// https://www.youtube.com/shorts/Pd4Mft9fJ_c?feature=share
// https://www.youtube.com/watch?v=DA_LZFaksSw
// https://youtu.be/DA_LZFaksSw

export function getYoutubeCode(url) {
  const patterns = [
    /https:\/\/www\.youtube\.com\/watch\?v=(.+)/,
    /https:\/\/www\.youtube\.com\/shorts\/([^?]+)(\?.*)?/,
    /https:\/\/youtu\.be\/(.+)/,
  ];

  const pattern = patterns.find((pattern) => pattern.test(url));

  if (!pattern) {
    return null;
  }

  const code = url.replace(pattern, "$1");

  // console.log(url, code);
  return code;
}
