<template>
  <div>
    <GridWidget :cols="cols" :gap="gap">
      <slot></slot>
    </GridWidget>
    <PaginationWidget v-if="countPages > 1" class="mt-10" :count="countPages" />
  </div>
</template>

<script>
import GridWidget from "./GridWidget.vue";
import PaginationWidget from "./PaginationWidget.vue";

export default {
  props: {
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    countPages: {
      type: [String, Number],
    },
  },
  components: {
    GridWidget,
    PaginationWidget,
  },
};
</script>
