<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <slot name="activator" v-bind="{ on: customEvents(on) }">
          <v-btn
            v-on="customEvents(on)"
            class="share text-none"
            v-bind="attrs"
            color="grey darken-3"
            depressed
            text
            :fab="small"
            :small="small"
          >
            <v-icon :left="!small" small> fa fa-share-alt </v-icon>
            <span v-if="!small">Compartilhar</span>
          </v-btn>
        </slot>
      </template>
      <v-list>
        <v-list-item
          v-for="({ color, icon, text, url }, i) of icons"
          @click="openWindow(url)"
          class="text-none text-body-2 font-weight-bold"
          :key="i"
        >
          <v-icon class="mr-2" :color="color" small> {{ icon }} </v-icon>
          {{ text }}
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icons() {
      const icons = {
        facebook: {
          color: "#4267b2",
          icon: "fa-facebook",
          text: "Facebook",
          url: `https://www.facebook.com/sharer.php?u=${
            this.server + this.$route.fullPath
          }`,
        },
        whatsapp: {
          color: "#25d366",
          icon: "fa-whatsapp",
          text: "WhatsApp",
          url: `https://wa.me/?text=${this.server + this.$route.fullPath}`,
        },
        twitter: {
          color: "#1da1f2",
          icon: "fa-twitter",
          text: "Twitter",
          url: `https://twitter.com/share?url=${
            this.server + this.$route.fullPath
          }`,
        },
      };

      return icons;
    },
  },
  methods: {
    openWindow(url) {
      window.open(
        url,
        "Compartilhar",
        "width=600,height=300,left=" +
          (screen.availWidth / 2 - 300) +
          ",top=" +
          (screen.availHeight / 2 - 150) +
          ""
      );
    },
    share(e, callback) {
      const shareData = {
        url: this.server + this.$route.fullPath,
      };

      if (navigator.share && window.innerWidth <= 600) {
        navigator.share(shareData);
        return;
      }

      callback(e);
    },
    customEvents(on) {
      const events = {
        click: (e) => this.share(e, on.click),
        keydown: (e) => this.share(e, on.keydown),
      };

      return events;
    },
  },
};
</script>

<style lang="scss" scoped>
.share {
  font-size: 14px;
}

.share-item {
  letter-spacing: 0.2px;
}
</style>
