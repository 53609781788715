<template>
  <PageTemplate v-bind="{ title, loading, noContainer }">
    <div><slot name="top"></slot></div>
    <div class="page-list-template_main">
      <SectionListTemplate
        :loading="loading"
        :no-data="noData"
        :count-pages="countPages"
        :cols="cols"
        :gap="gap"
        no-container
      >
        <div v-for="(item, i) in items" :key="i">
          <slot name="item" :item="item"></slot>
        </div>
      </SectionListTemplate>
      <div><slot name="side"></slot></div>
    </div>
    <div><slot name="bottom"></slot></div>
  </PageTemplate>
</template>

<script>
import PageTemplate from "./PageTemplate.vue";
import SectionListTemplate from "./SectionListTemplate.vue";

export default {
  props: {
    title: {
      type: String,
    },
    mod: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: () => ({}),
    },
    mapItemCallback: {
      type: Function, // (item: Object) => Object
      default: (item) => item,
    },
    itemsPerPage: {
      type: Number,
      default: 24,
      validator: (val) => val > 0,
    },
    returnTotalItems: {
      type: Boolean,
      default: true,
    },
    noContainer: {
      type: Boolean,
    },
    cols: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
    gap: {
      /**
       * {
       *   xs: [String, Number],
       *   sm: [String, Number],
       *   md: [String, Number],
       *   lg: [String, Number],
       *   xl: [String, Number],
       * }
       */
      type: Object,
    },
  },
  data: () => ({
    loading: true,
    totalItems: 0,
    items: [],
  }),
  computed: {
    page() {
      return this.$route.query.p;
    },
    countPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    noData() {
      return !this.items.length;
    },
  },
  methods: {
    getList() {
      this.loading = true;

      const params = {
        itemsPerPage: this.itemsPerPage,
        returnTotalItems: this.returnTotalItems ? 1 : 0,
        ...this.params,
      };

      if (this.page) {
        params.page = this.page;
      }

      return this.$rest(this.mod)
        .get({
          params,
          save: (state, data) => {
            let items = [];

            if (this.returnTotalItems) {
              items = data.list;
              this.totalItems = data.totalItems;
            } else {
              items = data;
            }

            this.items = items.map(this.mapItemCallback);
          },
        })
        .catch(() => this.$router.replace("/erro404"))
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    "$route.query"() {
      this.getList();
    },
  },
  created() {
    console.log(this.$vuetify);
    this.getList();
  },
  components: {
    PageTemplate,
    SectionListTemplate,
  },
};
</script>

<style>
.page-list-template_main {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 16px;
}

@media screen and (max-width: 1264px) {
  .page-list-template_main {
    grid-template-columns: 2fr 1fr;
  }
}
@media screen and (max-width: 959.9px) {
  .page-list-template_main {
    grid-template-columns: 1fr;
  }
}
</style>
