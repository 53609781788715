<template>
  <v-app>
    <LoadingWidget v-if="loading" />

    <template v-else>
      <router-link
        v-if="
          !['/apuracao-das-eleicoes-2024'].includes($route.path) && !smAndDown
        "
        to="/apuracao-das-eleicoes-2024"
      >
        <img
          class="d-block"
          src="./assets/img/banner-2024-10-03-1920x200.gif"
          width="100%"
        />
      </router-link>

      <HeaderShell />
      <NavigationWidget />

      <v-main>
        <router-link
          v-if="
            !['/apuracao-das-eleicoes-2024'].includes($route.path) && smAndDown
          "
          to="/apuracao-das-eleicoes-2024"
        >
          <img
            class="d-block mb-3"
            src="./assets/img/banner-2024-10-03-1190x250.gif"
            width="100%"
          />
        </router-link>

        <BannerWidget v-if="smAndDown" category="Top 1 (1180x190)" />
        <router-view v-bind="$route.meta" />
      </v-main>

      <FooterShell />
    </template>

    <CookieBannerWidget />
    <GalleryWidget />
  </v-app>
</template>

<script>
import HeaderShell from "./components/shell/header/HeaderShell.vue";
import FooterShell from "./components/shell/footer/Index.vue";
import CookieBannerWidget from "./components/widgets/CookieBannerWidget.vue";
import LoadingWidget from "./components/widgets/LoadingWidget.vue";
import GalleryWidget from "./components/widgets/GalleryWidget.vue";
import BannerWidget from "./components/widgets/BannerWidget.vue";
import NavigationWidget from "./components/widgets/NavigationWidget.vue";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    name() {
      return this.informations.name || "";
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    updateDocumentTitle() {
      if (this.name && this.$route.name) {
        document.title = `${this.$route.name} | ${this.name}`;
      } else if (this.name && !this.$route.name) {
        document.title = this.name;
      } else if (!this.name && this.$route.name) {
        document.title = this.$route.name;
      } else {
        document.title = "";
      }
    },
  },
  watch: {
    $route() {
      this.updateDocumentTitle();
    },
    name() {
      this.updateDocumentTitle();
    },
  },
  created() {
    this.updateDocumentTitle();
  },
  mounted() {
    const gettedNewsGategories = this.$rest("news-categories").get();
    const gettedCities = this.$rest("cities").get();
    const gettedInformations = this.$rest("informations").get({
      save: (state, data) => (state.item = data),
    });

    Promise.all([gettedNewsGategories, gettedCities, gettedInformations]).then(
      () => (this.loading = false)
    );
  },
  components: {
    HeaderShell,
    FooterShell,
    CookieBannerWidget,
    LoadingWidget,
    GalleryWidget,
    BannerWidget,
    NavigationWidget,
  },
};
</script>
